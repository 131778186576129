<template>
  <b-autocomplete
    ref="ac"
    v-model="namaData"
    :data="options"
    expanded
    field="nama"
    :loading="isFetching"
    @typing="fetchOptionsTyping"
    :placeholder="placeholder"
    :required="required"
    @select="onSelect"
    @blur="onBlur"
  ></b-autocomplete>
</template>

<script>
import axios from "axios";
import { debounce } from "lodash";
import APP_CONFIG from "@/apps/core/modules/config.js";

export default {
  name: "GenericAutocomplete",
  props: {
    apiPath: String,
    params: {},
    value: String, // uuid
    placeholder: String,
    required: { type: Boolean, default: true }
  },
  data() {
    return {
      isFetching: false,
      // syncParent: true,
      valueData: this.value,
      namaData: "",
      options: []
    };
  },
  methods: {
    onSelect(object) {
      const realValue = object ? object.id : null;
      this.valueData = realValue;
      this.$emit("input", this.valueData);
      this.$el.getElementsByTagName("input")[0].focus();
    },
    onBlur() {
      let el = this.$el.getElementsByClassName("dropdown-menu")[0];
      let style = getComputedStyle(el);
      let displayStyle = style.getPropertyValue("display");
      if (displayStyle === "none") {
        const namaArr = this.options.map(el => el.nama);
        const validNama = namaArr.includes(this.namaData);
        if (!validNama) {
          this.valueData = null;
          this.namaData = null;
          this.$emit("input", this.valueData);
          if (this.$refs.ac) {
            this.$refs.ac.checkHtml5Validity();
          }
        }
        this.$emit("blur");
      }
    },
    fetchOptions(inputStr, autoSetNama) {
      if (!autoSetNama) this.$emit("typing", inputStr);
      if (inputStr.length < 3) {
        self.options = [];
        return;
      }
      this.isFetching = true;
      const apiURL = `${APP_CONFIG.baseAPIURL}${this.apiPath}`;
      let params = this.params;
      if (params) {
        params.cari = inputStr;
      } else {
        params = { cari: inputStr };
      }
      axios
        .get(apiURL, { params: params })
        .then(response => {
          this.options = response.data.data;
          const optionsLen = response.data.data.length;
          if (autoSetNama && optionsLen == 1) {
            this.namaData = this.options[0].nama;
          }
        })
        .catch(() => {
          this.options = [];
        })
        .finally(() => {
          this.isFetching = false;
        });
    }
  },
  watch: {
    // When v-model is changed: update internal value
    value(val) {
      if (this.valueData !== val) {
        this.valueData = val;
        if (val) {
          this.fetchOptions(this.valueData, true);
        } else {
          this.namaData = null;
        }
      }
    }
  },
  created() {
    // fetchOptions tidak bisa disimpan di method,
    // karena debounce mengganggu keyword this.
    // debugger;
    this.fetchOptionsTyping = debounce(this.fetchOptions, 500);
    if (this.valueData) {
      this.fetchOptions(this.valueData, true);
    }
  }
};
</script>


<style scoped lang="scss">
.autocomplete {
  .dropdown-item {
    text-overflow: unset !important;
    white-space: unset !important;

    span {
      word-wrap: break-word;
    }
  }

  a.dropdown-item {
    padding-right: 1rem !important;
    color: #4a4a4a !important;
  }
}
</style>